import people1 from '../images/ourTeam/people-1.png'
import people2 from '../images/ourTeam/people-2.png'
import people3 from '../images/ourTeam/people-3.png'
import people4 from '../images/ourTeam/people-4.png'
import people5 from '../images/ourTeam/people-5.png'
import people6 from '../images/ourTeam/people-6.png'
import people7 from '../images/ourTeam/people-7.png'
import people8 from '../images/ourTeam/people-8.png'
import people9 from '../images/ourTeam/people-9.png'
import people10 from '../images/ourTeam/people-10.png'
import people11 from '../images/ourTeam/people-11.png'
import people12 from '../images/ourTeam/people-12.png'
import people13 from '../images/ourTeam/people-13.png'

export const PATH = {
  home: '/',
  about: '/about',
  ourTeam: '/about/our-team',
  careers: '/about/careers',
  services: '/services',
  socialMediaManagement: '/services/social-media-management',
  influencerMarketing: '/services/influencer-marketing',
  bcgArticle: '/articles/BCG-article',
  stArticle: '/articles/ST-article',
  getStarted: '/get-started',
  privacyPolicy: '/privacy-policy',
  termsOfService: '/terms-of-service',
  ourWork: '/ourWork/our-work',
  ourWorkDetail: '/ourWork/detail',
}

export const PATHNAME = {
  [PATH.home]: 'Home',
  [PATH.about]: 'About Us',
  [PATH.ourTeam]: 'Our Team',
  [PATH.careers]: 'Careers',
  [PATH.services]: 'Services',
  [PATH.socialMediaManagement]: 'Social Media Management and Paid Advertising',
  [PATH.influencerMarketing]: 'Influencer Marketing',
  [PATH.bcgArticle]: 'Detail Page',
  [PATH.stArticle]: 'Detail Page',
  [PATH.getStarted]: 'Get Started',
  [PATH.privacyPolicy]: 'Privacy Policy',
  [PATH.termsOfService]: 'Terms of Service',
  [PATH.ourWork]: 'Our Work',
}

export const LINKS = {
  tiktok: 'https://www.tiktok.com/@spoonxofficial?lang=zh-Hant-TW',
  youtube: 'https://www.youtube.com/channel/UCuGia7o_AbLeG7RSupMjgNg',
  instagram: 'https://www.instagram.com/spoonxofficial/',
  twitter: 'https://twitter.com/SpoonXofficial',
  facebook: 'https://www.facebook.com/SpoonXofficial',
  pitchbook:
    'https://pitchbook.com/profiles/company/168350-32?utm_source=google&utm_medium=cpc&utm_campaign=Profile-Previews-AS&adgroup=Profile-Previews-Company-Profiles&utm_term=&device=c&utm_content=&_bk=&_bt=572931937149&_bm=&_bn=g&_bg=118670843232&kwdaud=dsa-1046244901564&sfid=-dc_pcrid_572931937149_pkw__pmt__slid__productid__pgrid_118670843232_ptaid_dsa-1046244901564&gclid=CjwKCAjw6MKXBhA5EiwANWLODNGPoIoWKwCAVzHxDpLHFJ0yQnw9ATeJtaAIIwxgSVq1WNhQzPh4qBoCTaYQAvD_BwE#overview',
  crunchbase: 'https://www.crunchbase.com/organization/zaihui',
  linkedIn: 'https://www.linkedin.com/company/82358324/admin/',
}

export const PEOPLE_LIST = [
  [people1, people2, people4, people5, people8],
  [people9, people10, people11, people12, people13],
]

export const SOCIAL_MEDIA_MEMBERS = [
  people1,
  people2,
  people4,
  people5,
  people8,
  people9,
  people10,
  people11,
  people12,
  people13,
  people1,
  people2,
  people4,
  people5,
]

export const GOOGLE_ADS_ID = 'AW-16448478832/YzgCCJbStZcZEPC8n6M9'
